@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');


@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');


.modal-custom-height {
  min-height: 200px;
  /* Set your minimum height */
  max-height: 100vh;
  /* Set your maximum height relative to viewport */
  height: auto;
  /* Allow the modal to grow as needed */
  overflow-y: auto;
  /* Enable scrolling if content overflows */
}

.modal-content {
  height: auto;
  overflow-y: auto;
  /* Enable scrolling if content overflows */
  background-color: white;
  /* Ensure background is white */
}

/* .react-multiple-carousel__arrow--right {
  right: -10px !important;
  z-index: 100 !important;
} */