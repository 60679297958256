.step-btn-eastimation {
    height: 22px;
    width: 22px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 2px solid black; */
    font-size: 13px;
    font-weight: 500;
}

.slick-dots li button:before {
    color: white !important;
    line-height: 30px !important;
}

.slick-dots li {
    margin: 0px !important;
    padding: 0px !important;
}

.slick-next:before {
    opacity: 0 !important;
}

.slick-prev:before {
    opacity: 0 !important;
}