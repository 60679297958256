.next-btn {
    color: black;
    background-color: #FFD234;
    height: 40px;
    width: 146px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    font-weight: 600;
}

.prev-btn {
    color: black;
    border: #FFD234 2px solid;
    height: 40px;
    width: 146px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    font-weight: 600;
}

.number-input {
    -webkit-appearance: textfield;
    /* Override default Chrome and Safari style */
    -moz-appearance: textfield;
    /* Override default Firefox style */
    appearance: textfield;
    /* Override default style for modern browsers */

    /* Hide increment and decrement buttons */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Remove number input arrow */
    -moz-appearance: textfield;

    /* Disable cursor on input */
    cursor: default;
}