.diagram{
    clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 100% 88%, 94% 76%, 0% 75%);

}

li.react-multi-carousel-item.react-multi-carousel-item--active{
    width: 350px;
}
.react-multi-carousel-dot-list {
    justify-content: left !important;
    bottom: -4px !important;
    left: 2% !important;
}
.react-multi-carousel-dot--active button {
    background: #FFD234    ;
}
@media (max-width:640px) {
    .diagram{
        clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 100% 87%, 87% 75%, 0% 75%);
    
    } 
}