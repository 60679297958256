.focus\:ring-gray-200:focus {
    --tw-ring-color: none; 
}




.blink { 
    animation: blink-animation 1.3s infinite; 
  } 
    
  @keyframes blink-animation { 
    0% { 
      opacity: 1; 
    } 
    50% { 
      opacity: 0; 
    } 
    100% { 
      opacity: 1; 
    } 
  }